.#{$rt-namespace}__toast-container {
  z-index: $rt-z-index;
  -webkit-transform: translate3d(0, 0, #{$rt-z-index}px);
  position: fixed;
  padding: ($spacer / 3);
  width: $rt-toast-width;

  color: $white;

  &--top-left {
    top: 1em;
    left: 1em;
  }

  &--top-center {
    top: 1em;
    left: 50%;
    margin-left: -($rt-toast-width / 2);
  }

  &--top-right {
    top: 1em;
    right: 1em;
  }

  &--bottom-left {
    bottom: 1em;
    left: 1em;
  }

  &--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -($rt-toast-width / 2);
  }

  &--bottom-right {
    bottom: 1em;
    right: 1em;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;

    &--top-left,
    &--top-center,
    &--top-right {
      top: 0;
    }

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: 0;
    }

    &--rtl {
      right: 0;
      left: initial;
    }
  }
}
