// Settings
// ==================================================
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-layer-width: 24px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: $black !default;
$hamburger-layer-border-radius: 10px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (

        elastic,
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "scss/base";

// Hamburger types
// ==================================================

@import "scss/types/elastic";

// Fixes

.navbar-toggler {
  &:focus,
  &:active {
    outline: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}
