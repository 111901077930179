.table-running-statistic tbody {
    height: 175px;
    overflow-y: auto;
    width: 100%;
}

.table-running-statistic thead,
.table-running-statistic tbody,
.table-running-statistic tr,
.table-running-statistic td,
.table-running-statistic th {
    display: block;
}

.table-running-statistic tbody td,
.table-running-statistic tbody th,
.table-running-statistic thead>tr>th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}

.text-center.card-body.computer-off-0 {
    background-color: #dbdbdb;
    border-radius: 4px;
}

.c-order__nav {
    background: linear-gradient(142.23deg,#fff7f1 7.63%,#fae9ef 99.01%),linear-gradient(142.23deg,#fff7f1 7.63%,#fae9ef 99.01%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    border: 4px solid #fff;
    margin: 30px 0;
}