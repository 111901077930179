/**  =====================
      Prism css start
==========================  **/

pre[class*="language-"] {
    code {
        font-size: 14px;
    }

    [class*="language-"],
    .language-markup {
        background-color: transparent;
        display: block;
        padding: 10px 15px;
    }
}

pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
}

pre.line-numbers > code {
    position: relative;
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;
    /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.line-numbers-rows > span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
}

.line-numbers-rows > span:before {
    content: counter(linenumber);    
    display: block;
    padding-right: 0.8em;
    text-align: right;
}

div.code-toolbar {
    position: relative;
}

div.code-toolbar > .toolbar {
    position: absolute;
    top: 0.3em;
    right: 0.2em;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

div.code-toolbar:hover > .toolbar {
    opacity: 1;
}

div.code-toolbar > .toolbar .toolbar-item {
    display: inline-block;
}

div.code-toolbar > .toolbar a {
    cursor: pointer;
}

div.code-toolbar > .toolbar button {
    border: 0;
    font: inherit;
    line-height: normal;
    overflow: visible;
}

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar button,
div.code-toolbar > .toolbar span {
    color: #bbb;
    font-size: 0.8em;
    padding: 0 0.5em;
    background: #f5f2f0;
    background: rgba(224,224,224,.2);
    box-shadow: 0 2px 0 0 rgba(0,0,0,.2);
    border-radius: 0.5em;
}

div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar span:focus,
div.code-toolbar > .toolbar span:hover {
    color: inherit;
    text-decoration: none;
}
/**====== Prism css end ======**/
