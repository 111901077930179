@import 'variables';

.rbc-event {
  border: none;

  box-shadow: none;
  margin: 0;
  padding: $event-padding;
  background-color: $event-bg;
  border-radius: $event-border-radius;
  color: $event-color;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }

  &:focus {
    outline: 5px auto $event-outline;
  }
}

.rbc-event-label {
  @extend .rbc-ellipsis;
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  @include border-left-radius(0);
}
.rbc-event-continues-after {
  @include border-right-radius(0);
}


.rbc-event-continues-earlier {
  @include border-top-radius(0);
}
.rbc-event-continues-later {
  @include border-bottom-radius(0);
}
