//  Core

.popover {
  box-shadow: $box-shadow-sm;
}

.popover-custom-lg {
  & > .popover {
    max-width: $popover-max-width * 1.5;
  }
}

.popover-custom-md {
  & > .popover {
    width: $popover-max-width * 1.5;
    max-width: $popover-max-width * 1.5;
  }
}

.popover-custom-xl {
  & > .popover {
    width: $popover-max-width * 2;
    max-width: $popover-max-width * 2;
  }
}

.popover-custom-wrapper {
  & > .popover {
    .popover-body {
      padding: 0;
    }
  }
}

.popover-max-width {
  & > .popover {
    max-width: none;
  }
}

@each $color, $value in $theme-colors {
  .popover-#{$color} {
    background: $value;

    .popover-body {
      color: color-yiq($value);
    }

    &.bs-popover-top {
      > .arrow {
        &::before {
          border-top-color: $popover-arrow-outer-color;
        }

        &::after {
          border-top-color: $value;
        }
      }
    }

    &.bs-popover-right {
      > .arrow {
        &::before {
          border-right-color: $popover-arrow-outer-color;
        }

        &::after {
          border-right-color: $value;
        }
      }
    }

    &.bs-popover-bottom {
      > .arrow {
        &::before {
          border-bottom-color: $popover-arrow-outer-color;
        }

        &::after {
          border-bottom-color: $value;
        }
      }
    }

    &.bs-popover-left {
      > .arrow {
        &::before {
          border-left-color: $popover-arrow-outer-color;
        }

        &::after {
          border-left-color: $value;
        }
      }
    }
  }
}
