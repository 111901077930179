/*
 *  STYLE 4
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
  height: 10px; /* Giảm kích thước thanh cuộn ngang */
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}



/* ==========================================================================
   Header
   ========================================================================== */
.navbar-wrapper {
  .css-1pecj7g {
    position: absolute;
    padding-top: 16px;
    margin: auto 16px 16px;
    opacity: 1;
    background: transparent;
    color: #344767;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .css-1dridtm {
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    min-width: auto;
    background-image: url(https://demos.creative-tim.com/soft-ui-dashboard-react/static/media/white-curved.a5b7db662b0b14ab5534.jpeg);
    background-position: 50% center;
    background-size: cover;
    border-radius: 1rem;
    box-shadow: none;
  }

  .css-1882gvv {
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgb(255, 255, 255);
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 16px;

    &::after {
      content: "";
      background-image: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0.65;
      z-index: -1;
    }
  }

  .css-1ssrsgn {
    width: 2rem;
    height: 2rem;
    margin-bottom: 16px;
    opacity: 1;
    background: rgb(255, 255, 255);
    color: rgb(52, 71, 103);
    border-radius: 0.5rem;
    box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
    display: grid;
    place-items: center;
    transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .css-uujcwg {
    line-height: 1;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
  }

  .css-ija0gt {
    margin: 0px;
    font-size: 1rem;
    line-height: 1.625;
    font-weight: 500;
    letter-spacing: 0.0075em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }

  .css-i7eqfq {
    margin: 0px;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.03333em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 500;
  }

  .btn-logout {
    padding: 0.5rem 2rem;
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 10px;
    background: #fff;
    border-radius: 5px;
    color: rgb(52, 71, 103);
    text-transform: uppercase;
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.22);
    font-size: 12px;
    font-weight: 600;
  }
}


.navbar-wrapper {
  .btn-link {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-menu-lg {
    z-index: 20;
    border: 0.0625rem solid rgb(222, 226, 230);

  }
}


#mobile-collapse {
  display: none;
}

.pcoded-inner-navbar {
  height: calc(100vh - 209px);
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.pcoded-menu-caption {
  h6 {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    opacity: .7;
    margin-top: 13px;
    position: relative;
    padding-left: 19px;

    &::after {
      content: "--";
      font-weight: 900;
      position: absolute;
      left: 3px;
      top: -1px;
    }
  }
}

.c-intro {
  padding: 15px 0 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.parallax>use {
  animation: move-forever 12s linear infinite;

  &:nth-child(1) {
    animation-delay: -2s;
  }

  &:nth-child(2) {
    animation-delay: -2s;
    animation-duration: 5s
  }

  &:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 3s
  }
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%)
  }

  100% {
    transform: translate(85px, 0%)
  }
}



.editorial {
  display: block;
  width: 100%;
  height: 3em;
  max-height: 100vh;
  margin: 0;
}

.pcoded-navbar {
  .pcoded-inner-navbar {
    flex-wrap: nowrap !important;
  }
}

.nav-link {
  padding: 10px 6px;

  .pcoded-micon,
  .pcoded-mtext {
    transition: all .1s linear;
  }

  &:hover {
    background: #f5f5f5;



    .pcoded-mtext {
      color: rgb(33, 82, 255);
    }

  }

  &.active {
    border-radius: 9999px !important;
    color: #fff;
    font-weight: 600;
    background: linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255));


    .pcoded-micon {
      background-color: #fff !important;
      color: #000;
      border-radius: 9999px !important;
    }

  }
}

.income-card {
  margin-bottom: 20px;

  .card-body {
    padding: 15px 10px !important;
  }

  h5 {
    background-color: rgba(36, 105, 92, 0.1);
    align-items: center;
    border-radius: 27px;
    display: flex;
    height: 35px;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 35px;
    font-size: 12px;
    font-weight: 500;
  }

  &__name {
    font-size: 19px;
    font-weight: 700;
    padding: 1px 10px;
    border-radius: 4px;
    margin-left: 5px;

    &--green {
      background-color: rgb(130, 214, 22, .2);
      color: rgb(130, 214, 22);
    }

    &--yellow {
      color: #fb4;
      background-color: rgb(255 187 68 / 23%);
    }

    &--red {
      background-color: rgb(234 6 6 / 23%);
      color: rgb(234, 6, 6);
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* ==========================================================================
   Tổng quan
   ========================================================================== */
.list-server {
  &__item {
    border: 1px solid #dee2e6 !important;
    border-radius: 8px;
    background: linear-gradient(310deg, rgba(20, 23, 39, 0.8), rgba(58, 65, 111, 0.8)), url(./images/apps/bg-cover.jpg);
    border-radius: 8px;
    background-position: 50% center;

    b.d-block {
      font-size: 27px;
      margin-bottom: 6px;
      color: rgb(52, 71, 103);
    }
  }

  .line-height-sm {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    -webkit-backdrop-filter: saturate(229%) blur(1px);
    backdrop-filter: saturate(229%) blur(1px);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    position: relative;
    padding: 10px;
    max-width: 50%;
    margin: 0 auto;
  }
}

.badge-primary {
  background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
}

.numbers {
  margin: 10px 0;
}

.bg-gradient-secondary {
  overflow: hidden;
}

.icon-shape {
  width: 48px;
  height: 48px;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  border-radius: 0.5rem;
}

.react-datepicker__header {
  background: linear-gradient(310deg, #21d4fd, #2152ff);
  color: #fff;
}

.react-datepicker {
  border: 1px dotted #21d4fd;
}

.card-box {
  &__inner {
    background: linear-gradient(310deg, rgba(33, 82, 255, 0.6), rgba(33, 212, 253, 0.6)) 50% center / cover,
      url(./images/apps/bg-cover.jpg) transparent;
    border-radius: 8px;
    background-position: 50% center;
    padding: 50px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-size: cover;
    position: relative;
    z-index: 999;

    & * {

      color: rgb(52, 71, 103);
    }

    &__text {
      border: 0px solid rgba(0, 0, 0, 0.125);
      border-radius: 1rem;
      backdrop-filter: saturate(229%) blur(1px);
      background-color: rgba(255, 255, 255, 0.6);
      box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
      position: relative;
      padding: 16px;
      max-width: 50%;
      margin: 0 auto;
    }
  }

  &__bottom {
    padding: 15px 0px;
  }
}

.c-chart {
  box-shadow: none !important;

  .card {
    margin: 0 !important;
  }

  label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .react-datepicker-wrapper {
    width: 90%;
  }
}



/* ==========================================================================
   Cài đặt
   ========================================================================== */
.p-settingCard {
  &__heading {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    backdrop-filter: saturate(229%) blur(1px);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    position: relative;
    padding: 16px;
    color: #344767;
  }

  .card-header-alt {
    background: linear-gradient(310deg, rgba(20, 23, 39, 0.6), rgba(58, 65, 111, 0.6)) center center / cover no-repeat, url(./images/apps/curved.jpg);
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-position: 50% center;
    padding: 50px;
  }

  .icon-settings {
    padding: 10px;
    color: rgb(52, 71, 103);
    background-color: #fff;
    border-radius: 50%;
    transform: rotate(0);
    animation: around 4s linear infinite;
  }

  h3 {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .btn-primary {
    color: #fff;
    border-color: transparent;
    background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111));
    padding: 9px 100px;
  }
}

@keyframes around {
  to {
    transform: rotate(360deg);
  }
}


.app-page-title {
  background: linear-gradient(310deg, rgba(20, 23, 39, 0.6), rgba(58, 65, 111, 0.6)) center center / cover no-repeat, url(./images/apps/curved.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding: 80px 25px;
  margin: 0;
  margin-bottom: 43px;
  border-radius: 8px;

  &--description {
    display: none;
  }

  &__text {
    background: linear-gradient(310deg, #2152ff, #21d4fd);
    color: #fff;
    padding: 2px 6px;
    border-radius: 4px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    backdrop-filter: saturate(200%) blur(30px);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    position: relative;
    padding: 15px 25px;
    color: #344767;
    position: absolute;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -25px;
    z-index: 10;

    &--iconbox {
      background: #fff;
      border-radius: 0.29rem;
      box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, .03), 0 0.9375rem 1.40625rem rgba(59, 62, 102, .03), 0 0.25rem 0.53125rem rgba(59, 62, 102, .05), 0 0.125rem 0.1875rem rgba(59, 62, 102, .03);
      display: flex;
      margin: 0 1.5rem 0 0;
      justify-content: center;
      align-items: center;
    }

  }
}

.page-header {
  background: linear-gradient(310deg, rgba(20, 23, 39, 0.6), rgba(58, 65, 111, 0.6)) center center / cover no-repeat, url(./images/apps/curved.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding: 80px 25px;
  margin-bottom: 43px;
  border-radius: 8px;

  &__content {
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    backdrop-filter: saturate(200%) blur(30px);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    position: relative;
    padding: 16px;
    color: #344767;
    position: absolute;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -25px;
    z-index: 10;
  }

  i.feather {
    background: linear-gradient(310deg, #21d4fd, #2152ff);
    box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, .2);
    color: #fff;
    font-size: 1.5rem;
    padding: 4px 7px;
    margin-right: 10px;
    border-radius: 4px;
    width: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    text-align: center;
  }

  span {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #344767;
  }
}

.table-responsive {
  th {
    font-size: 13px;
  }

  tr {
    td {
      &:first-child {
        font-weight: bold;
      }
    }
  }
}

.ant-table-cell {
  .btn-icon {
    width: 100% !important;
    height: initial !important;
    border-radius: 4px;
    padding: 1px 8px !important;

    svg {
      width: 60% !important;
      margin: 0 auto;
    }

    &:nth-child(1) {
      background-color: #0ce7fa57;

      path {
        fill: #4ac18e;
      }
    }

    &:nth-child(2) {
      background-color: #50c7934f;

      path {
        fill: #fb4;
      }
    }

    &:nth-child(3) {
      background-color: #fa916b78;

      path {
        fill: #ea553d;
      }
    }
  }
}


.c-create {
  .nav-item {
    padding: 0 !important;
    width: 100%;

    .nav-link {
      &.active {
        color: #fff !important;
        border-radius: 0px !important;
        text-align: center;
        background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111)) !important;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}


.modal-footer {
  .btn-primary {
    background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111)) !important;
  }
}

.modal-header {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
  text-align: center;
  justify-content: center;
  padding: 30px 10px;
  text-transform: uppercase;

  .modal-title {
    color: #fff;
    font-weight: 700;
  }
}

.btn-primary {
  color: #fff;
  background-color: rgb(20, 23, 39);
  border-color: rgb(20, 23, 39);
}

.btn-danger {
  color: #fff;
  background-color: rgb(234, 6, 6);
  border-color: rgb(234, 6, 6);
}

.card-header {
  .card-toolbar {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    padding: 20px 15px;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    background: #f4f7fa;
  }

  label {
    font-size: 12px;
    line-height: 1;
  }

  .ant-btn.ant-btn-primary {
    background-color: rgb(20, 23, 39);
    width: 100%;
  }
}



.css-w0ozu7 {
  width: calc(100% - 2rem);
  min-height: 50vh;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 48px;
  padding-bottom: 224px;
  opacity: 1;
  color: rgb(52, 71, 103);
  background: linear-gradient(310deg, rgba(20, 23, 39, 0.6), rgba(58, 65, 111, 0.6)) center center / cover no-repeat, url(./images/apps/curved.jpg) transparent;
  border-radius: 0.75rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.css-k538ac {
  margin-top: -192px;
}

#kt_login_signin_form,
#kt_login_signup_form {
  max-width: 400px;
  margin: 0 auto;

  .btn-primary {

    background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111));

  }
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 10px 10px;
}

table {
  .text-dark {
    color: #f44236 !important;
    font-size: 12px;
  }
}

.c-customer {
  .nav-tabs {
    padding: 0 0.6666666667rem;
    width: 100%;
  }

  .nav-item {
    width: 100%;
    flex: 1;
    text-align: center;

    a {
      width: 100%;
      justify-content: center;
    }
  }

  .nav-link {
    font-weight: 500;
    border-radius: 8px !important;

    &:hover {
      background: #fff !important;
    }

    &.active {
      color: #fff !important;
      border-top-width: 0px !important;
      background: #3a416f !important;
    }
  }
}

.c-order__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px !important;
  border: 0;
  color: #fff;
  background-color: #3a416f !important;

  &+button {
    margin-top: 5px;
  }

  &--trash {
    background-color: #f44236 !important;
    color: #fff !important;
  }
}

.flex-direction-column {
  flex-direction: column;
}

.c-btnHistory {
  min-width: 110px;

  &+.c-btnHistory {
    margin-top: 8px;
  }
}


.caption {
  display: inline-block;
  position: relative;
  z-index: 999;
}

.caption::before {
  content: attr(data-title);
  display: block;
  white-space: nowrap;
  position: absolute;
  background: #000;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px;
  left: 50%;
  bottom: 22px;
  font-size: 12px;
  color: #fff;
  opacity: 0;
  transform: translateX(-50%);
}

.caption::after {
  bottom: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  border-top-color: #000;
  border-width: 6px;
  z-index: 99999999;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}


.caption:hover::before,
.caption:hover::after {
  opacity: 1;
}


.c-order__rate {
  padding: 1px 8px;
  border-radius: 4px;

  &--green {
    background-color: rgb(33 212 253 / 22%);
    color: #21d4fd;
  }

  &--yellow {
    color: #fb4;
    background-color: rgb(255 187 68 / 23%);
  }

  &--red {
    background-color: rgb(234 6 6 / 23%);
    color: rgb(234, 6, 6);
  }
}

.modal-account-die {
  .modal {
    &-body {
      height: 100%;
      overflow: auto;
    }
    &-dialog {
      width: 90%;
      max-width: 100%;
      height: calc(100vh - 56px);
    }

    &-content {
      height: 100%;
    }
  }

}

.table{
  &--custom {
    overflow: scroll;
    height: 75vh;
  }
}