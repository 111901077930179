.table-running-statistic tbody {
    height: 175px;
    overflow-y: auto;
    width: 100%;
}

.table-running-statistic thead,
.table-running-statistic tbody,
.table-running-statistic tr,
.table-running-statistic td,
.table-running-statistic th {
    display: block;
}

.table-running-statistic tbody td,
.table-running-statistic tbody th,
.table-running-statistic thead>tr>th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}